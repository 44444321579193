export const environment = {
    production: false,
    useStorageEmulator: false,
    useAuthEmulator:false,
    useFunctionEmulator: false,
    managementApiEndpoint: "https://localhost:7145/",
    // managementApiEndpoint: "https://grts-manage-api-dev.azurewebsites.net/",
    firebase: {
        apiKey: "AIzaSyDIhxo9drGJ-7r_4IVkCj0hj_oRP44cavE",
        authDomain: "solid-scheme-383010.firebaseapp.com",
        projectId: "solid-scheme-383010",
        storageBucket: "grts-portal-dev",
        messagingSenderId: "44373413829",
        appId: "1:44373413829:web:768e56d1870e72cbfc05ac",
        measurementId: "G-P9KVCT6QEL",
        mapsApiKey: "AIzaSyD-41BGujGvLBq8AKx-nVqpZB5AKUVA0rU",
        websiteDatabaseName: "website",
        databaseName: "portal",
        region: "europe-west2"       
    },
    reCAPTCHASiteKey:"6Ldj0vwpAAAAAEGMe2POGxwYrYm9kG0pK7fawg_x",
    appCheckDebugToken: "BDDB13D9-900C-4D5E-B37E-6955767165D5",
    eventDaySiteUrl: "https://dev.grts.uk/"
};

